import React from 'react'
import Sidebar from '../components/Sidebar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
 faBell,
} from '@fortawesome/free-regular-svg-icons'
import {
 faCaretDown
}from '@fortawesome/free-solid-svg-icons'
//import ReportTable from '../components/ReportTable';
import { DataGrid,GridToolbar } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';

const Report = () => {
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
    const VISIBLE_FIELDS = ['Invoice No.', 'Date', 'Status', 'Customer', 'Purchase'];
    const { data } = useDemoData({
        
        rowLength: 10,
        maxColumns: 6,
        visibleFields: VISIBLE_FIELDS,
      });

    const columns = [
        { field: "Invoice ", accessor: "invoiceNumber", headerName: 'Invoice', width: 150,sortable: true },
        {field: "Date", accessor: "date", width: 150, sortable: true },
        {field: "Status", accessor: "status", width: 150, sortable: true },
        { field: "Customer", accessor: "customer", width: 150, sortable: false },
        { field: "Purchase", accessor: "purchases", width: 200, sortable: false },
       
      ];

      const Items = [
        {
            "invoiceNumber":1,
            "date":5/12/2022,
            "status":"active",
            "customer":"naza",
            "purchase":"monthly subscription"


        }
      ]
  return (
   <>
     <Sidebar />
    <div className='relative md:ml-64 bg-blueGray-100'>
    <nav className="absolute top-0 left-0 w-full">
                    <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
                        {/* Brand */}
                        <a
                            className=" text-lg  hidden lg:inline-block font-semibold"
                            href="#pablo"
                            style={{color:'#671c2d'}}
                            onClick={e => e.preventDefault()}
                        >
                            Cash flow forecast report
                        </a>
                  
                        {/* User */}
                        <FontAwesomeIcon icon={faBell} className="border px-3 py-3 rounded-full" style={{fontSize:13}} />
                    </div>
                </nav>
        <div className="py-11">
                <div className="border-t border-gray-200"></div>
              </div>

              <div className='grid grid-cols-4 gap-3 m-5'>
                    <div className='flex border px-4 py-2 rounded-lg space-x-5'>
                    <p>Report from:<span className=' font-bold relative left-3'>Select Date</span></p>
                    <FontAwesomeIcon icon={faCaretDown} className=' m-1' />
                    </div>
                    <div className='border px-4 py-2 rounded-lg'>Forecast Period:<span className=' font-bold'>180days</span></div>
                    <div className='flex  border px-4 py-2 rounded-lg space-x-12'>
                    <p className='relative space-x-3'>Select report:<span className=' font-bold relative left-3'>All</span></p>
                    <FontAwesomeIcon icon={faCaretDown} className=' m-1 items-end text-end' />
                    </div>
                    <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                
                                <button onClick={() => setShowModal(true)}
                                    className=" text-white active:bg-red-600 text-xs font-bold px-12 py-3 rounded outline-none focus:outline-none mr-1 mb-1"
                                    type="button"
    
                                    style={{ transition: "all .15s ease",backgroundColor:'#df3c62' }}
                                >
                                    Show report
                                </button>
                               
                            </div>
                            
                </div>
             
                <div className=' m-6 ' style={{ height: 400, width: '93%' }}>
      <DataGrid
       slots={{
          toolbar: GridToolbar,
          // Use custom FilterPanel only for deep modification
          // FilterPanel: MyCustomFilterPanel,
        }}
        
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        {...data}
        data = {Items}
        columns={columns}
        initialState={{
          ...data.initialState,
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        pageSizeOptions={[5, 10, 25]}
      />
    </div>
    
    </div>
   </>
  )
}

export default Report