import { configureStore } from "@reduxjs/toolkit"

import authReducer from "./slices/auth"
import zohoReducer from "./slices/zoho"
import userReducer from "./slices/user"
import forecastReducer from "./slices/forecast"
import inventoryReducer from "./slices/inventory"
import nonbillReducer from "./slices/nonbill"
const store = configureStore({
    reducer: {
        auth: authReducer,
        zoho: zohoReducer,
        user: userReducer,
        forecast: forecastReducer,
        inventory: inventoryReducer,
        nonbill : nonbillReducer
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export { store }