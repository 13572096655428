import { createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const theme = createTheme({
  typography: {
    h2: {
      fontSize: "2rem",
      fontWeight: 700,
      borderBottom: "2px solid black",
      "@media print": {
        fontSize: "8rem",
        borderBottom: "20px solid red",
        color: "blue"
      }
    }
  }
});
export default function PrintableTable() {
  return (
    <ThemeProvider theme={theme}>
      <Typography variant="h2">Hello CodeSandbox</Typography>
    </ThemeProvider>
  );
}