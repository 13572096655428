import React from 'react'
import Sidebar from '../components/Sidebar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
 faBell,
} from '@fortawesome/free-regular-svg-icons'
import {
 faCaretDown
}from '@fortawesome/free-solid-svg-icons'
//import ReportTable from '../components/ReportTable';
import { DataGrid,GridToolbar } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';

const Item = () => {
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
    const VISIBLE_FIELDS = ['Item  ID', 'Item Name', 'Available Stock'];
    const { data } = useDemoData({
        
        rowLength: 10,
        maxColumns: 6,
        visibleFields: VISIBLE_FIELDS,
      });

    const columns = [
        { field: "item_id",  sortable: true,sortbyOrder: "asc" },
        {field: "name", sortable: true },
        {field: "available_stock",  sortable: true  },
       
      ];

      const item =  [
        {
            "name":"rytuy",
            "item_id":"1000000",
            "available_stock":3,
            "rate": 120,
            "description": "500GB",
            "tax_id": 982000000037049,
            "purchase_tax_rule_id": 127919000000106780,
            "sales_tax_rule_id": 127919000000106780,
            "tax_percentage": "70%",
            "hsn_or_sac": "string",
            "sat_item_key_code": "string",
            "unitkey_code": "string",
            "sku": "s12345",
            "product_type": "goods",
            "is_taxable": true,
            "tax_exemption_id": "string",
            "account_id": " ",
            "avatax_tax_code": 982000000037049,
            "avatax_use_code": 982000000037049,
            "item_type": " ",
            "purchase_description": " ",
            "purchase_rate": " ",
            "purchase_account_id": " ",
            "inventory_account_id": " ",
            "vendor_id": " ",
            "reorder_level": " ",
            "initial_stock": " ",
            "initial_stock_rate": " ",
            "item_tax_preferences": [
                {
                    "tax_id": 982000000037049,
                    "tax_specification": "intra"
                }
            ],
            "custom_fields": [
                {
                    "customfield_id": "46000000012845",
                    "value": "Normal"
                }
            ]
        }
    ]
    
    
  return (
   <>
     <Sidebar />
    <div className='relative md:ml-64 bg-blueGray-100'>
    <nav className="absolute top-0 left-0 w-full">
                    <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
                        {/* Brand */}
                        <a
                            className=" text-lg  hidden lg:inline-block font-semibold"
                            href="#pablo"
                            style={{color:'#671c2d'}}
                            onClick={e => e.preventDefault()}
                        >
                            Cash flow forecast report
                        </a>
                  
                        {/* User */}
                        <FontAwesomeIcon icon={faBell} className="border px-3 py-3 rounded-full" style={{fontSize:13}} />
                    </div>
                </nav>
        <div className="py-11">
                <div className="border-t border-gray-200"></div>
              </div>

              <div className='grid grid-cols-4 gap-3 m-5'>
                  
                    <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                
                                <button onClick={() => setShowModal(true)}
                                    className=" text-white active:bg-red-600 text-xs font-bold px-12 py-3 rounded outline-none focus:outline-none mr-1 mb-1"
                                    type="button"
    
                                    style={{ transition: "all .15s ease",backgroundColor:'#df3c62' }}
                                >
                                    Show report
                                </button>
                               
                            </div>
                            
                </div>
             
                <div className=' m-6 ' style={{ height: 400, width: '93%' }}>
      <DataGrid
       slots={{
          toolbar: GridToolbar,
          // Use custom FilterPanel only for deep modification
          // FilterPanel: MyCustomFilterPanel,
        }}
        
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        {...data}
        data = {item}
        columns={columns}
        initialState={{
          ...data.initialState,
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        pageSizeOptions={[5, 10, 25]}
      />
    </div>
    
    </div>
   </>
  )
}

export default Item