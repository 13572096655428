import React from 'react'
import Sidebar from '../components/Sidebar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import logo from '../assets/logo.png'
import Box from '@mui/material/Box'
import {
 faBell,
} from '@fortawesome/free-regular-svg-icons'
import {
 faCaretDown
}from '@fortawesome/free-solid-svg-icons'
//import ReportTable from '../components/ReportTable';
import { Button } from '@mui/material';
import { DataGrid,GridToolbar,GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector, 
  GridExcelExportOptions,
  GridFooterPlaceholder,
  GridPrintExportOptions,
  GridPrintExportMenuItem,

  GridPanelFooter, GridPanelHeader} from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import  Modal from '../components/Modal'
import ExpensesTable from "../components/ExpensesTable";
import { getPrepaidExpense,savePrepaidExpense} from '../redux/slices/nonbill';
import { InfinitySpin } from "react-loader-spinner"
import BillChart from '../components/BillChart';
import { COLORS } from '@mui/x-data-grid-generator/services/static-data';
import './expense.css'
import logo from "../assets/logo.png"
import { logout } from '../redux/slices/auth';
import PrintableTable from './PrintableTable';
function CustomToolbar() {
 
  
  return (
    <GridToolbarContainer>
    
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector
        slotProps={{ tooltip: { title: 'Change density' } }}
      />
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarExport
       
        slotProps={{
          tooltip: { title: 'Export data' },
          button: { variant: 'outlined' },
         
          
        }}

        

        printOptions={{ disableToolbarButton: false ,  includeCheckboxes: false,  pageStyle: '.MuiDataGrid-root .MuiDataGrid-main { color: rgba(0, 0, 0, 0.87); background-color: gray} ', hideToolbar: true, fileName: 'yourFileName', bodyClassName: '.header',  header: () => (
            <div className="header">
              {/* Your header content goes here */}
              <h1>This is the header</h1>
            </div>
          ),}}
        
     >
      
     
      </GridToolbarExport>
     
    
    </GridToolbarContainer>

  );
}

const Prepayed = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [currencyId,setCurrencyId] = useState('');
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
    const [paymentConfirmation,setPaymentConfirmation]= useState(0)
    const [selectedRow,setSelectedRow] = React.useState(null)
    const VISIBLE_FIELDS = ['Account ID', 'Tax Id', 'Payment Date','Description','Is Taxable','Amount','Currency ID','Reference Number','Customer Id','Non Billable'];
    const { data } = useDemoData({
        
        rowLength: 10,
        maxColumns: 6,
        visibleFields: VISIBLE_FIELDS,
      });

      const confirmation = () =>{
        setPaymentConfirmation(!paymentConfirmation)
      }
      const[isLoading,setIsLoading] = useState(true)
      const dispatch = useDispatch();
      const isExpensesLoading = useSelector(
        (state) => state.nonbill.isExpensesLoading
      );
    
      const expenses = useSelector((state) => state.nonbill.expenses);

      const expensesWithId = expenses.map(expense => ({
        ...expense,
        id: expense.expense_id
      }));

       
      const handleInputChange =(e)=>{
        const {name,value} = e.target
        setEditData((formData)=>({
          ...formData,
          [name]:value
        }))
      }
      const handleEdit = (rowId) => {

        setIsEditing(true);
        const editedRow = expenses.find((row)=>row.id===rowId)
        // You can add more logic here, such as fetching data for editing, etc.
       setEditData(editedRow)
      };

      const handleCloseModal = () => {
        setIsEditing(false);
        setEditData(null);
        setSelectedRow(null);
      };

      /*const handleSave = () =>{
        // const Save =rowData.findIndex((row)=>row.account_id===editData.account_id)
         //const updateExpenses = [...rowData]
         //updateExpenses[Save] = editData
         //setExpense(updateExpenses)\
         dispatch(savePrepaidExpense(editData)); 
        
         setIsEditing(false)
         setEditData(null)
       }*/

       const handleSave = async (event) => {
        
        event.preventDefault();
        
        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData.entries());
        console.clear()
        console.log('i am here')
        console.log(data,'>>>>>>>>>>>>>>>>>>>......5')
        
        try {
            const response = await fetch(`${process.env.REACT_APP_MANIFOLD_API_URL}/zoho/updatePrepaid`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
    
            if (!response.ok) {
                throw new Error('Failed to submit form');
            }
          
            // Clear the form
            event.target.reset();
            
            // Reload the page
            window.location.reload();
        } catch (error) {
            console.error('Error:', error.message);
            // Handle errors
        }
    };
 

      const handleChangeCurrencyID= (e)=>{
        setCurrencyId(e.target.value)
    
      }
      /*const handleDelete = (rowId) => {
      
        dispatch(deletePrepaidExpense(rowId));
       
      };*/

      const columns = [
        { headerName: "Account  ID", field: "account_id", sortable: true,sortbyOrder: "asc" },
        { headerName: "Tax Id", field: "tax_id", sortable: true },
        { headerName: "Payment Date", field: "payment_date", sortable: false },
        { headerName: "Description", field: "description", sortable: false },
        { headerName: "Is taxable ", field: "is_taxable", sortable: false },
        { headerName: "Amount ", field: "amount", sortable: false },
        { headerName: "Currency", field: "'currency", sortable: false },
        { headerName: "Currency ID", field: "currency_id", sortable: false },
        { headerName: "Reference Number", field: "total_without_tax", sortable: false },
        { headerName: "Customer Id", field: "customer_id", sortable: false },
        { headerName: "Non Billable", field: "non_billable", sortable: false },
        /*{
          headerName: 'Delete',
          field: 'delete',
          sortable: false,
          width: 100,
          renderCell: (params) => (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                console.log('Clicked delete button for row:', params.row);
                handleDelete(params.row.id); // Assuming each row has an id
              }}
            >
              Delete
            </Button>
          ),
        },*/
        
      ];
      
      const [isInclusiveTax, setIsInclusiveTax] = useState(false);
      const [isBillable, setIsBillable] = useState(false);
console.log('newnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn')
      const toggleButton = () =>{
        setIsInclusiveTax(!isInclusiveTax)
      }
      const Toggle = () =>{
         setIsBillable(!isBillable)
      }
   
    
    useEffect(() => {
        dispatch(getPrepaidExpense())
          .then((result) => {
            console.log(result, "mmmmmmmmmmmm");
            setIsLoading(false)
          })
          .catch((error) => {
            console.error(error); 
            setIsLoading(false)
          });
      }, [dispatch]); 

      const handleRowClick = (params) => {
        setSelectedRow(params.row);
      };

      const handleSubmit = async (event) => {
        event.preventDefault();
        
        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData.entries());
        console.log(data,'>>>>>>>>>>>>>>>>>>>...')
        
        try {
            const response = await fetch(`${process.env.REACT_APP_MANIFOLD_API_URL}/zoho/postPrepaid`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
    
            if (!response.ok) {
                throw new Error('Failed to submit form');
            }
    
            // Clear the form
            event.target.reset();
            
            // Reload the page
            window.location.reload();
        } catch (error) {
            console.error('Error:', error.message);
            // Handle errors
        }
    };
    const getCurrentDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      let month = today.getMonth() + 1;
      let day = today.getDate();
  
      // Add leading zero if month or day is less than 10
      month = month < 10 ? '0' + month : month;
      day = day < 10 ? '0' + day : day;
  
      return `${year}-${month}-${day}`;
  };

 
 
  return (
   <>
     <Sidebar />
    
   
    <div className='relative md:ml-64 bg-blueGray-100'>
    <nav className="absolute top-0 left-0 w-full">
                    <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
                        {/* Brand */}
                        <a
                            className=" text-lg  hidden lg:inline-block font-semibold"
                            href="#pablo"
                            style={{color:'#671c2d'}}
                            onClick={e => e.preventDefault()}
                        >
                            Prepaid Expenses
                        </a>
                  
                        {/* User */}
                        <FontAwesomeIcon icon={faBell} className="border px-3 py-3 rounded-full" style={{fontSize:13}} />
                    </div>
                </nav>
        <div className="py-11">
                <div className="border-t border-gray-200"></div>
              </div>
           
              {
  isLoading ? 
  <div className="flex justify-center h-screen items-center ">
  <InfinitySpin width="200" color="red"/> 
  </div>:
             
              <>
              <div className=' relative -top-8 float-end'>
              <button onClick={() => setShowModal(true)}
                                    className=" text-white active:bg-red-600 text-xs font-bold px-12 py-3 rounded outline-none focus:outline-none mr-1 mb-1"
                                    type="button"
    
                                    style={{ transition: "all .15s ease",backgroundColor:'#df3c62' }}
                                >
                                   Expenses
                                </button>
              </div>
                {/* Modal */}
                {showModal ? (
                    <>
                        <div
                            className=" justify-center  flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                        >
                            <div className="max-w-md">
                                {/*content*/} 
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start justify-between mt-5 rounded-t">
                                        <div className="flex-initial relative left-5 ">
                                            <h3 className="text-black font-bold text-lg mb-1 " >
                                              Add Expenses
                                            </h3>
                                        </div>
                                        <button
                                            onClick={() => setShowModal(false)}
                                            type="button"
                                            className=" rounded-full relative -top-6 p-1 inline-flex items-center justify-center text-black hover:text-black bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500" style={{backgroundColor:'#e6e8ec'}}>
                                            <span className="sr-only">Close menu</span>

                                            <svg className="h-5 w-5 rounded-full" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                     
                                    </div>
                                    <div className="py-1 px-2">
                <div className="border-t border-gray-200"></div>
              </div>
                                    {/*body*/}
                                    <form className='space-y-3 py-9' onSubmit={handleSubmit}>
                                        <div className="relative px-2 flex-auto">
                                            <div className="relative">
                                            <div className="hidden sm:block" aria-hidden="true">
             
            </div>
            <div className=' grid grid-cols-2 space-x-3'>
              <div>

              <input
                                                    type='text'
                                                    name='account_id'
                                                    placeholder="Account ID"
                                                    style={{ transition: "all .15s ease" }}
                                                    className="w-full px-2 py-3 rounded-lg bg-white mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                                                    required

                                                />

                                              
                                              <input
                                                    type='date'
                                                    name='payment_date'
                                                    min={getCurrentDate()}
                                                    placeholder="Expected Payment Date"
                                                    style={{ transition: "all .15s ease" }}
                                                    className="w-full px-4 py-3 rounded-lg bg-white mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                                                    required

                                                />

                                                <input
                                                    type='number'
                                                    name='amount'
                                                    placeholder="Amount"
                                                    style={{ transition: "all .15s ease" }}
                                                    className="w-full px-4 py-3 rounded-lg bg-white mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                                                    required

                                                />

<input
                                                    type='text'
                                                    name='customer_id'
                                                    placeholder="Customer ID"
                                                    style={{ transition: "all .15s ease" }}
                                                    className="w-full px-4 py-3 rounded-lg bg-white mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                                                   

                                                />

<input
                                                    type='string'
                                                    name='currency_id'
                                                    value={currencyId}
                                                    disabled
                                                    placeholder="Currency ID"
                                                    style={{ transition: "all .15s ease" }}
                                                    className="w-full px-4 py-3 rounded-lg bg-white mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                                                   

                                                />

<select
    type='string'
    name='currency'
    onChange={handleChangeCurrencyID}
    placeholder="Currency"
    style={{ transition: "all .15s ease" }}
    className="w-full px-4 py-3 rounded-lg bg-white mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
>
    <option value="1">Naira (NGN)</option>
    <option value="2">USD (United States Dollar)</option>
</select>
                                                
                                                
              </div>
              <div>

              <input
                                                    type='string'
                                                    name='tax_id'
                                                    placeholder="Tax ID"
                                                    style={{ transition: "all .15s ease" }}
                                                    className="w-full px-4 py-3 rounded-lg bg-white mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                                                   

                                                />
                                                 <div className='flex'>
                                       <label className='my-5 relative text-sm'>IsTaxable?</label>
                                        <input
                                        
      type='button'
      name='is_inclusive_tax'
      onClick={toggleButton}
      value={isInclusiveTax ? "true" : "false"}
      placeholder="Enter true or false"
      style={{ transition: "all .15s ease" }}
      className="w-full px-4 py-3 h-8 rounded-lg bg-white mt-4 border focus:border-grey-200 focus:bg-white focus:outline-none"
    />

                                       </div>

<div className='flex'>
<label className='my-5 relative text-sm'>Payment Confirmed?</label>
<input
                                        
                                        type='button'
                                        name='payment confirmation'
                                        onClick={confirmation}
                                        value={paymentConfirmation ? 0 : 1}
                                        placeholder="Enter true or false"
                                        style={{ transition: "all .15s ease" }}
                                        className="w-full px-4 py-3 h-8 rounded-lg bg-white mt-4 border focus:border-grey-200 focus:bg-white focus:outline-none"
                                      />
                                     </div>
                                  


<input
                                                    type='string'
                                                    name='reference_number'
                                                    placeholder="Reference Number"
                                                    style={{ transition: "all .15s ease" }}
                                                    className="w-full px-4 py-3 rounded-lg bg-white mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                                                   

                                                />

<input
                                                    type='string'
                                                    name='description'
                                                    placeholder="Description"
                                                    style={{ transition: "all .15s ease" }}
                                                    className="w-full px-4 py-3 rounded-lg bg-white mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                                                   

                                                />
    <div className='flex'>
                                        <label className='my-5 relative text-sm'>isBillable?</label>

<input
                                        
                                        type='button'
                                        name='is_Billable'
                                        onClick={Toggle}
                                        value={isBillable ? "true" : "false"}
                                        placeholder="Enter true or false"
                                        style={{ transition: "all .15s ease" }}
                                        className="w-full px-4 py-3 h-8 rounded-lg bg-white mt-4 border focus:border-grey-200 focus:bg-white focus:outline-none"
                                      />
                                        </div> 
              </div>
            </div>  
                                            </div>
                                        </div>
                                        {/*footer*/}
                                        <div className="flex items-center justify-center p-6 mt-5 rounded-b">

                                            <button
                                                className="inline-flex justify-center py-3 px-40 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 mb-1"
                                                type="submit"
                                                style={{backgroundColor:'#df3c62'}}
                                               
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
               ) : null} 
            
               <div className=' m-6' style={{ height: 400, width: '93%' }}>
               
                {!isExpensesLoading && (
                   <>
               
                   <DataGrid
         
        slots={{
          toolbar: CustomToolbar, 
        }}
        
       checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        {...data}
        rows={expenses} // Use expensesWithId here
        getRowId={(row) => row.id} 
        onRowClick={handleRowClick}
       columns={columns}
         initialState={{
          ...data.initialState,
          pagination: { paginationModel: { pageSize: 5 } },
         
        }}
        pageSizeOptions={[5, 10, 25]}
      />
       
       {selectedRow && (
        <>
        <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                        isOpen={selectedRow !== null} 
                      >
                      <form onSubmit={handleSave}>
                      <div className=" w-auto my-9 mx-auto max-w-xl">
                          {/*content*/}
                          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-around mt-80 rounded-t">
                              <div className="flex-initial w-64 justify-center">
                                <h3 className="text-red-700 font-medium text-lg mb-1 ">
                                Accrued Expenses
                                </h3>
                              
                              </div>
                            
                              <div className="flex-initial ">
                             
                             <button
                               onClick={handleEdit}
                               type="button"
                               className="rounded-md p-1 inline-flex items-center justify-center text-blue-400 hover:text-blue-500 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
                             >
                               <span className="sr-only">Edit menu</span>
                               <svg
                                 className="h-5 w-5"
                                 xmlns="http://www.w3.org/2000/svg"
                                 fill="none"
                                 viewBox="0 0 24 24"
                                 stroke="currentColor"
                                 aria-hidden="true"
                               >
                                 <path
                                   strokeLinecap="round"
                                   strokeLinejoin="round"
                                   strokeWidth="2"
                                   d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                 />
                                 <path
                                   strokeLinecap="round"
                                   strokeLinejoin="round"
                                   strokeWidth="2"
                                   d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                 />
                               </svg>
                             </button>
                             <button
                                         onClick={handleCloseModal}
                                           type="button"
                                           className=" rounded-md p-1 inline-flex items-center justify-center text-red-400 hover:text-red-500 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500"
                                         >
                                           <span className="sr-only">Close menu</span>
           
                                           <svg
                                             className="h-5 w-5"
                                             xmlns="http://www.w3.org/2000/svg"
                                             fill="none"
                                             viewBox="0 0 24 24"
                                             stroke="currentColor"
                                             aria-hidden="true"
                                           >
                                             <path
                                               strokeLinecap="round"
                                               strokeLinejoin="round"
                                               strokeWidth="2"
                                               d="M6 18L18 6M6 6l12 12" />
                                           </svg>
                                         </button>
                             </div>
                            </div>
                            {/*body*/}
                            <div className="relative px-8 flex-auto">
                              <div className="relative w-12/12">
                              <div className="mb-4 md:flex md:justify-between">
                                  <div className="w-full">
                                    <label className="block text-gray-700">
                                    Account ID
                                    </label>

                                    <input
                                      type="text"
                                      name="account_id"
                                      value={editData ? editData.account_id : selectedRow.account_id}
                                      onChange={handleInputChange}
                                      style={{ transition: "all .15s ease" }}
                                      className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none" />
                                  </div>
                                </div>
                                <div className="mb-4 md:flex md:justify-between">
                                  <div className="w-full">
                                    <label className="block text-gray-700">
                                    Tax ID
                                    </label>

                                    <input
                                      type="text"
                                      name="tax_id"
                                      value={editData ? editData.tax_id : selectedRow.tax_id}
                                      onChange={handleInputChange}
                                      style={{ transition: "all .15s ease" }}
                                      className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none" />
                                  </div>
                                </div>

                                <div className="mb-4 md:flex md:justify-between">
                                  <div className="w-full">
                                    <label className="block text-gray-700">
                                    Payment Date
                                    </label>

                                    <input
                                      type="date"
                                      name="payment_date"
                                      min={getCurrentDate}
                                      onChange={handleInputChange}
                                      value={(editData ?  editData.payment_date:selectedRow.payment_date)}
                                      style={{ transition: "all .15s ease" }}
                                      className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none" />
                                  </div>
                                </div>

                                <div className="mb-4 md:flex md:justify-between">
                                  <div className="mb-4 md:mr-2 md:mb-0">
                                    <label className="block text-gray-700">
                                      Description
                                    </label>

                                    <input
                                      type="text"
                                      name="description"
                                      value={editData?editData.description:selectedRow.description}
                                      onChange={handleInputChange}
                                      style={{ transition: "all .15s ease" }}
                                      className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none" />
                                  </div>
                                  <div className="mb-4 md:mr-2 md:mb-0">
                                    <label className="block text-gray-700">
                                    Amount
                                    </label>
                                        
                                    <input
                                      type="text"
                                      name="amount"
                                      value={editData?editData.amount:selectedRow.amount}
                                      onChange={handleInputChange}
                                      style={{ transition: "all .15s ease" }}
                                      className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none" />
                                  </div>
                                </div>

                                <div className="mb-4 md:mr-2 md:mb-0">
                                    <label className="block text-gray-700">
                                   Currency
                                    </label>
                                        
                                    
                                <select
   type='text'
    name='currency'
    placeholder="Currency"
    value={editData?editData.currency:selectedRow.currency}
    onChange={handleChangeCurrencyID}
    
    style={{ transition: "all .15s ease" }}
    className="w-full px-4 py-3 rounded-lg bg-white mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
>
    <option value="1">Naira (NGN)</option>
    <option value="2">USD (United States Dollar)</option>
</select>
                                  </div>

   
                                  <input
                                                    type='hidden'
                                                    name='id'
                                                    placeholder="Account ID"
                                                    value={selectedRow.id}
                                                    readOnly
                                                    style={{ transition: "all .15s ease" }}
                                                    className="w-full px-2 py-3 rounded-lg bg-white mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                                                    required

                                                />

                                <div className="mb-4 md:flex md:justify-between">
                                  <div className="mb-4 md:mr-2 md:mb-0">
                                    <label className="block text-gray-700">
                                    Is Taxable
                                    </label>

                                    <input
                                      type="text"
                                      name="is_taxable"
                                      value={editData?editData.is_taxable:selectedRow.is_taxable}
                                      onChange={handleInputChange}
                                      style={{ transition: "all .15s ease" }}
                                      className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none" />
                                  </div>
                                  <div className="mb-4 md:mr-2 md:mb-0">
                                    <label className="block text-gray-700">
                                    Reference Number
                                    </label>

                                    <input
                                      type="text"
                                      name="reference_number"
                                      value={editData?editData.reference_number:selectedRow.reference_number}
                                      onChange={handleInputChange}
                                      style={{ transition: "all .15s ease" }}
                                      className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none" />
                                  </div>
                                </div>
                                <div className="mb-4 md:mr-2 md:mb-0">
                                    <label className="block text-gray-700">
                                    Payment Confirmation
                                    </label>

                                    <input
                                      type="text"
                                      name="paymentConfirmation"
                                      value={editData?editData.paymentConfirmation:selectedRow.paymentConfirmation}
                                      onChange={handleInputChange}
                                      style={{ transition: "all .15s ease" }}
                                      className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none" />
                                  </div>
                               

                                <div className="mb-4 md:flex md:justify-between">
                                  <div className="mb-4 md:mr-2 md:mb-0">
                                    <label className="block text-gray-700">
                                    Customer ID
                                    </label>
                                    
                                    <input
                                      type="text"
                                      name="customer_id"
                                      value={editData?editData.customer_id:selectedRow.customer_id}
                                      onChange={handleInputChange}
                                      style={{ transition: "all .15s ease" }}
                                      className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none" />
                                  </div>
                                  <div className="mb-4 md:mr-2 md:mb-0">
                                    <label className="block text-gray-700">
                                   Currency ID
                                    </label>
                                    <input
                                      type="text"
                                      name="currency_id"
                                      value={currencyId}
                                      readOnly
                                      onChange={handleInputChange}
                                      style={{ transition: "all .15s ease" }}
                                      className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none" />
                                    
                                  </div>
                                  <div className="mb-4 md:mr-2 md:mb-0">
                                    <label className="block text-gray-700">
                                   Non Billable
                                    </label>

                                    <input
                                      type="text"
                                      name="non_billable"
                                      value={editData?editData.non_billable:selectedRow.non_billable}
                                      onChange={handleInputChange}
                                      style={{ transition: "all .15s ease" }}
                                      className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none" />
                                  </div>
                                </div>
                              </div>
                        <div className=' justify-center items-center text-center'>
                        <button
              // Attach the handleSave function to the onClick event
              type="submit"
              className="rounded-md px-4 py-2 items-center bg-green-500 text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              Save
            </button>
                        </div>
                            </div>
                            {/*footer*/}
                            <div className="flex items-center justify-center p-6 mt-5 rounded-b"></div>
                          
                          </div>
                        </div>
                    </form>
                     
                      </div>
                      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                      </>
        /*<Modal isOpen={selectedRow !== null} onClose={handleCloseModal}>
                   <ul className='list-none'>
                    <li className=" border-b border-gray-200 py-2 text-lg"><strong>Account Id:</strong> {selectedRow.account_id}</li>
                    <li className=" border-b border-gray-200 py-2 text-lg"><strong> tax_id:</strong> {selectedRow.tax_id}</li>
                    <li className=" border-b border-gray-200 py-2 text-lg"><strong>Payment date:</strong> {selectedRow.payment_date}</li>
                    <li className=" border-b border-gray-200 py-2 text-lg"><strong>Description: </strong>{selectedRow.description}</li>
                    <li className=" border-b border-gray-200 py-2 text-lg"><strong>amount:</strong> {selectedRow.amount}</li>
                    <li className=" border-b border-gray-200 py-2 text-lg"><strong> currency id: </strong>{selectedRow.currency_id}</li>
                    <li className=" border-b border-gray-200 py-2 text-lg"><strong>total_without_tax:</strong> {selectedRow.total_without_tax}</li>
                    <li className=" border-b border-gray-200 py-2 text-lg"><strong> customer_id:</strong> {selectedRow.customer_id}</li>
                    <li className=" border-b border-gray-200 py-2 text-lg"><strong>non_billable:</strong> {selectedRow.non_billable}</li>
                   </ul>
                  </Modal>*/
      )}
                   </>
     

                    
                )}
                
    </div>
    
   
    </> 
             }
             
             <footer className="block py-4">
                <div className="container mx-auto px-4">
                  <hr className="mb-4 border-b-1 border-blueGray-200" />
                  <div className="flex flex-wrap items-center md:justify-between justify-center">
                    <div className="w-full md:w-4/12 px-4">
                      <div className="text-sm text-blueGray-500 font-semibold py-1">
                        Copyright © {new Date().getFullYear()}{" "}
                        <a
                          href="#"
                          className="text-blueGray-500 hover:text-blueGray-700 text-sm font-semibold py-1"
                        >
                          Manifold Computers
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
    </div>
  
   </>
  )
}
export default Prepayed