import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Axios } from '../../api/instances';
import { toast } from 'react-toastify';



const initialState = {
  isItemsLoading: false,
  error: null,
  items: [],
  
};



const getInventory = createAsyncThunk('item', async () => {
   try {
    let accessToken = localStorage.getItem('accessToken')
      ? JSON.parse(localStorage.getItem('accessToken'))
      : null;
      let zohoAccessToken = localStorage.getItem('zohoAccessToken')
        ? JSON.parse(localStorage.getItem('zohoAccessToken'))
        : null;
    const res = await Axios.post(
        "/zoho/getinventory",
       {"accessToken":zohoAccessToken},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          
        }
      );
      console.clear()
      console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
      console.log(res)
      

    return res.data.data;
  } catch (error) {
    throw error.response.data || error.message;
  }
});



const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getInventory.pending, (state, action) => {
        state.isItemsLoading = true;
      })
      .addCase(getInventory.fulfilled, (state, action) => {
        console.log(typeof action.payload,"problem")
        state.items = action.payload;
        state.isItemsLoading = false;
      })
      .addCase(getInventory.rejected, (state, action) => {
        toast.warning('An error occured ', {
          autoClose: 2000,
        });
        state.isItemsLoading = false;
      })
      
  },
});

export {
 getInventory
};

export default inventorySlice.reducer;
