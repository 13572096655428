import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormat from "react-currency-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InvoiceTableBody from "../components/InvoiceTableBody";
import{
  faBell
}from '@fortawesome/free-regular-svg-icons'
import CurrencyInput from "react-currency-input-field";
//import { getNonBillable } from "../redux/slices/expenses";
//import ExpensesTable from '../components/ExpensesTable';
import axios from 'axios';

import {
  Navigate,
  useSearchParams,
  useNavigate,
  NavLink,
} from "react-router-dom";
import { InfinitySpin } from "react-loader-spinner";

import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import LineChart from "../components/LineChart";
import BarChart from "../components/BarChart";
import dayjs from "dayjs";
import { zoho } from "../redux/slices/zoho";
import { logout, getUser } from "../redux/slices/auth";
import { generateReport } from "../redux/slices/forecast";
import {
  faWallet,
  faGem,
  faReceipt,
  faFileInvoice,
} from "@fortawesome/free-solid-svg-icons";
import { withAuth } from "../hoc/withAuth";
import BillTable from "../components/BillTable";
import SaleTable from "../components/SaleTable";
import PurchaseTable from "../components/PurchaseTable";
import InvoiceTable from "../components/InvoiceTable";
import {Button} from '@mui/material'
import { DataGrid,GridToolbar } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
//import Expenses from "./Expenses";
import  Modal from '../components/Modal'

const CurrencyCell = ({ rows,field }) => {
  console.log("Rows:", rows); // Debugging statement

  const { balance, currencyCode , nairaBalance , dollarBalance } = rows;

  console.log("Balance:", balance); // Debugging statement
  console.log("Currency Code:", currencyCode); // Debugging statement

  // Check if balance is a valid number
  const parsedBalance = parseFloat(rows.balance);
  const currencBalance = parseFloat(rows.nairaBalance)
  console.log(currencBalance,'currencBalance')
  console.log("Parsed Balance:", parsedBalance); // Debugging statement

  if (isNaN(parsedBalance)) {
    // If balance is not a valid number, display a fallback value
    return (
      <td className="py-4 px-6 text-sm font-medium text-black whitespace-nowrap dark:text-black">
        Invalid Balance
      </td>
    );
  }

  
  

  // Filter balance based on currencyCode
  let currencyBalance = null;
  if (field === "nairaBalance" && currencyCode === "NGN") {
    currencyBalance = parsedBalance;
  } else if (field === "dollarBalance" && currencyCode === "USD") {
    currencyBalance = parsedBalance;
  }else if (field === "balance") {
    currencyBalance = parsedBalance;
  }
  // Render the currency cell with currency-specific balance
  return (
    <td className="py-4 px-6 text-sm font-medium text-black whitespace-nowrap dark:text-black">
    {currencyBalance !== null && (
      <>
        {currencyCode === "NGN" && (
          <span className="font-semibold text-sm">&#8358;</span>
        )}
        {currencyCode === "USD" && (
          <span className="font-semibold text-sm">&#36;</span>
        )}
        <CurrencyFormat
          value={parseFloat(currencyBalance)}
          displayType="text"
          thousandSeparator={true}
          decimalScale={2}
        />
      </>
    )}
  </td>
);
};


const Dashboard = (props) => {
  const [selectedRow,setSelectedRow] = useState(null)
  const [showInflowTable,setShowInflowTable] = useState(true)
  const [showOutflowTable,setShowOutflowTable] = useState(true)
  const handleRowClick = (params) => {
    setSelectedRow(params.row);
  };

   const hideTable = () =>{
      setShowInflowTable(!showInflowTable)
   }
   const closeTable = () =>{
    setShowOutflowTable(!showOutflowTable)
 }
  const handleCloseModal = () => {
    setSelectedRow(null);
  };
  let [searchParams, setSearchParams] = useSearchParams(props);
  // grant user access to zoho
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const VISIBLE_FIELDS = ['#', 'Invoice No.', 'Customer Name','Naira Balance','Dollar Balance','Balance Due','Due Date','Currency','Status'];
 
  const { data } = useDemoData({
        
        rowLength: 10,
        maxColumns: 6,
        visibleFields: VISIBLE_FIELDS,
      });

      const updateSaleDetail = (params) => {
        setShowSaleDetailModal(true);
        setSaleDetail(params.row);
      }

      const updateInvoicesDetail = (params) => {
        setShowInvoiceDetailModal(true);
        setInvoiceDetail(params.row);
      }

      const updateBillsDetail = (params) => {
        setShowBillDetailModal(true);
        setBillDetail(params.row);
      }

      const updatePurchaseDetail = (params) => {
        setShowPurchaseDetailModal(true);
        setPurchaseDetail(params.row);
      }

    
  let [zohoGrant, setZohoGrant] = useState(false);
  let [isLoading, setIsLoading] = useState(true);
  let [showInvoiceDetailModal, setShowInvoiceDetailModal] = useState(false);
  let [invoiceDetail, setInvoiceDetail] = useState("");
  let [showSaleDetailModal, setShowSaleDetailModal] = useState(false);
  let [saleDetail, setSaleDetail] = useState("");
  let [showBillDetailModal, setShowBillDetailModal] = useState(false);
  let [billDetail, setBillDetail] = useState("");
  let [showPurchaseDetailModal, setShowPurchaseDetailModal] = useState(false);
  let [purchaseDetail, setPurchaseDetail] = useState("");
  let [expensesDetail,setExpensesDetail] = useState("");
  let [showExpensesDetailModal, setShowExpensesDetailModal] = useState(false);
  let [code, setCode] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const zohoLoading = useSelector((state) => state.zoho.isLoading);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isSynchronizing = useSelector(
    (state) => state.forecast.isSynchronizing
  );
  let isZohoAuthenticated = useSelector(
    (state) => state.auth.isZohoAuthenticated
  );
  let isDownloadingReport = useSelector(
    (state) => state.forecast.isDownloadingReport
  );
  let isGeneratingReport = useSelector(
    (state) => state.forecast.isGeneratingReport
  );
  let { forecastNumber, forecastPeriod } = useSelector(
    (state) => state.forecast.forecastInfo
  );
  //let isExpensesLoading = useSelector((state)=>state.expenses.isExpensesLoading)
  //let expense = useSelector((state)=>state.expenses.expense)
  //console.log(expense,"reeeeeeeeee")
  let { openingBalance, totalCashInflow, totalCashOutflow, closingBalance } =
    useSelector((state) => state.forecast.report);
  let invoices = useSelector((state) => state.forecast.invoices);
  console.log(invoices,"wwwwwwwwwwe")
  let sales = useSelector((state) => state.forecast.sales);
  let bills = useSelector((state) => state.forecast.bills);
  let purchases = useSelector((state) => state.forecast.purchases);
  let user = useSelector((state) => state.auth.user);
  let zohoAuthenticated = localStorage.getItem("zohoAuthenticated")
    ? localStorage.getItem("zohoAuthenticated")
    : false;
  let { selectedPeriod } = useSelector(
    (state) => state.forecast.forecastDropdown
  );

  const invoiceColumns = [
    { label: "#", accessor: "id", sortable: true, sortbyOrder: "asc" },
    { label: "Invoice No.", accessor: "invoiceNumber", sortable: true },
    { label: "Customer Name", accessor: "customerName", sortable: true },
    { label: "Naira Balance", accessor: "nairaBalance", sortable: false },
    { label: "Dollar Balance", accessor: "dollarBalance", sortable: false },
    { label: "Balance Due", accessor: "balance", sortable: true },
    {
      label: "Due Date",
      accessor: "dueDate",
      sortable: true,
    },
    {
      label: "Currency",
      accessor: "currencyCode",
      sortable: true,
    },
    {
      label: "Status",
      accessor: "status",
      sortable: true,
    },
  ];

  const saleColumns = [
    { label: "#", accessor: "id", sortable: true, sortbyOrder: "asc" },
    { label: "Sale ID", accessor: "salesOrderNumber", sortable: true },
    { label: "Customer Name", accessor: "customerName", sortable: true },
    { label: "Naira Balance", accessor: "nairaBalance", sortable: false },
    { label: "Dollar Balance", accessor: "dollarBalance", sortable: false },
    { label: "Balance Due", accessor: "balance", sortable: true },
    {
      label: "Shipment Date",
      accessor: "shipmentDate",
      sortable: true,
    },
    {
      label: "Currency",
      accessor: "currencyCode",
      sortable: true,
    },
    {
      label: "Status",
      accessor: "status",
      sortable: true,
    },
  ];

 

const expensesColumns = [
  { label: "Expenses  ID", accessor: "expense_id", sortable: true,sortbyOrder: "asc" },
  { label: "date", accessor: "date", sortable: true },
  { label: "Account Name", accessor: "account_name", sortable: false },
  { label: "Description", accessor: "description", sortable: false },
  { label: "Account Paid", accessor: "paid_through_account_name", sortable: false },
  { label: "Currency Code", accessor: "currency_code", sortable: false },
  { label: "Currency ID", accessor: "currency_id", sortable: false },
  { label: "Total Tax", accessor: "total_without_tax", sortable: false },
  { label: "Status", accessor: "status", sortable: false },
];

  

  const billColumns = [
    { label: "#", accessor: "id", sortable: true, sortbyOrder: "asc" },
    { label: "Bill ID", accessor: "refrenceNumber", sortable: true },
    { label: "Vendor Name", accessor: "vendorName", sortable: true },
    { label: "Naira Balance", accessor: "nairaBalance", sortable: false },
    { label: "Dollar Balance", accessor: "dollarBalance", sortable: false },
    { label: "Balance Due", accessor: "balance", sortable: true },
    {
      label: "Due Date",
      accessor: "dueDate",
      sortable: true,
    },
    {
      label: "Currency",
      accessor: "currencyCode",
      sortable: true,
    },
    {
      label: "Status",
      accessor: "status",
      sortable: true,
    },
  ];

  const purchaseColumns = [
    { label: "#", accessor: "id", sortable: true, sortbyOrder: "asc" },
    { label: "Purchase ID", accessor: "purchaseOrderNumber", sortable: true },
    { label: "Vendor Name", accessor: "vendorName", sortable: true },
    { label: "Naira Balance", accessor: "nairaBalance", sortable: false },
    { label: "Dollar Balance", accessor: "dollarBalance", sortable: false },
    { label: "Balance Due", accessor: "balance", sortable: true },
    {
      label: "Delivery Date",
      accessor: "deliveryDate",
      sortable: true,
    },
    {
      label: "Currency",
      accessor: "currencyCode",
      sortable: true,
    },
    {
      label: "Status",
      accessor: "status",
      sortable: true,
    },
  ];


  const ExpenseColumns = [
    { label: "#", accessor: "id", sortable: true, sortbyOrder: "asc" },
    { label: "Expenses", accessor: "expense_id", sortable: true },
    { label: "Date", accessor: "date", sortable: true },
    { label: "Account Name", accessor: "account_name", sortable: true },
    { label: "Description", accessor: "description", sortable: true },
    { label: "Account Paid", accessor: "paid_through_account_name", sortable: true },
    { label: "Dollar Balance", accessor: "currency_id", sortable: false },
    { label: "Total", accessor: "total_without_tax", sortable: true },
    {
      label: "Currency",
      accessor: "currency_code",
      sortable: true,
    },
    {
      label: "Status",
      accessor: "status",
      sortable: true,
    },
  ];

  

  /*useEffect(() => {
    dispatch(getNonBillable())
      .then((result) => {
        console.log(result, "uuuuuuuuuuuuunnnnnnnuuuuuuu");
        
      })
      .catch((error) => {
        console.error(error); 
      });
      
  }, [dispatch]); */
  

  useEffect(() => {
    dispatch(getUser());
    if (searchParams.get("error") === "access_denied") {
      navigate("/login");
      dispatch(logout());
      return;
    }

    if (!zohoAuthenticated && searchParams.get("code")) {
      setZohoGrant(true);
      setCode(searchParams.get("code"));
      dispatch(zoho({ code: searchParams.get("code") }));

      return;
    }

    if (!zohoAuthenticated && isAuthenticated) {
      dispatch(zoho({ code: "" }));
    }
    if (localStorage.getItem("zohoAccessToken")) {
      if (zohoAuthenticated && isAuthenticated && !isLoading) {
        dispatch(
          generateReport({ id: selectedPeriod, forecastNumber, forecastPeriod })
        );
      }
    }

    setIsLoading(false);
    setZohoGrant(true);
  }, [zohoGrant, searchParams, localStorage.getItem("zohoAccessToken")]);

  return (
    <>
      {isZohoAuthenticated && isLoading && isGeneratingReport && (
        <Navigate to="/" replace={true} />
      )}

      {(isLoading || isSynchronizing) && (
        <>
          <div className="grid h-screen place-items-center">
            {isSynchronizing && (
              <>
                <h1>Please wait while the application syncs...</h1>
              </>
            )}
            <InfinitySpin width="200" color="red" />
          </div>
        </>
      )}

      {isGeneratingReport && (
        <>
          <div className="grid h-screen place-items-center">
            <div>
              <h1>Please wait while we generate your report...</h1>
              <div className="flex items-center justify-center">
                <InfinitySpin width="200" color="red" />
              </div>
            </div>
          </div>
        </>
      )}

      {!zohoGrant && !isAuthenticated && !isLoading && (
        <Navigate to="/login" replace={true} />
      )}

      {zohoAuthenticated && !isGeneratingReport && (
        <>
          <Sidebar />
          <div className="relative md:ml-64 bg-blueGray-100">
           
          <Navbar user={user}  />
        <div className="py-16">
                <div className="border-t border-gray-200"></div>
              </div>
            
            {showInvoiceDetailModal ? (
              <>
                <div
                  className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                  onClick={() => setShowInvoiceDetailModal(false)}
                >
                  <div className="fixed w-auto my-6 mx-auto max-w-xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                      {/*header*/}
                      <div className="flex items-start justify-around mt-5 rounded-t">
                        <div className="flex-initial w-64 justify-center">
                          <h3 className="text-red-700 font-medium text-lg mb-1 ">
                            Invoice Detail
                          </h3>
                        </div>
                        <button
                          onClick={() => setShowInvoiceDetailModal(false)}
                          type="button"
                          className=" rounded-md p-1 inline-flex items-center justify-center text-red-400 hover:text-red-500 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500"
                        >
                          <span className="sr-only">Close menu</span>

                          <svg
                            className="h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                      {/*body*/}
                      <div className="relative px-8 flex-auto">
                        <div className="relative w-12/12">
                          <p className="my-4 text-slate-500 text-lg leading-relaxed">
                            Invoice detail for{" "}
                            <a
                              target="_blank"
                              href={
                                process.env.REACT_APP_ZOHO_BASE_URL +
                                "/" +
                                process.env.REACT_APP_ORGANIZATION_ID +
                                "#/invoices/" +
                                invoiceDetail.invoiceId
                              }
                              class="text-blue-500 underline"
                            >
                              {invoiceDetail.invoiceId}
                            </a>
                          </p>

                          <div className="mb-4 md:flex md:justify-between">
                            <div className="w-full">
                              <label className="block text-gray-700">
                                Customer Name
                              </label>

                              <input
                                type="text"
                                name="email"
                                value={invoiceDetail.customerName}
                                style={{ transition: "all .15s ease" }}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                              />
                            </div>
                          </div>

                          <div className="mb-4 md:flex md:justify-between">
                            <div className="w-full">
                              <label className="block text-gray-700">
                                Reference Number
                              </label>

                              <input
                                type="text"
                                name="email"
                                value={invoiceDetail.refrenceNumber}
                                style={{ transition: "all .15s ease" }}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                              />
                            </div>
                          </div>

                          <div className="mb-4 md:flex md:justify-between">
                            <div className="mb-4 md:mr-2 md:mb-0">
                              <label className="block text-gray-700">
                                Invoice Number
                              </label>

                              <input
                                type="text"
                                name="email"
                                value={invoiceDetail.invoiceNumber}
                                style={{ transition: "all .15s ease" }}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                              />
                            </div>
                            <div className="mb-4 md:mr-2 md:mb-0">
                              <label className="block text-gray-700">
                                Status
                              </label>

                              <input
                                type="text"
                                name="email"
                                value={invoiceDetail.status}
                                style={{ transition: "all .15s ease" }}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                              />
                            </div>
                          </div>

                          <div className="mb-4 md:flex md:justify-between">
                            <div className="mb-4 md:mr-2 md:mb-0">
                              <label className="block text-gray-700">
                                Balance
                              </label>
                              <CurrencyInput
                                intlConfig={{
                                  locale:
                                    invoiceDetail.currencyCode != "USD"
                                      ? "en-NG"
                                      : "en-US",
                                  currency:
                                    invoiceDetail.currencyCode != "USD"
                                      ? "NGN"
                                      : "USD",
                                }}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                                id="innvoice-balance"
                                name="invoice-balance"
                                defaultValue={invoiceDetail.balance}
                                decimalsLimit={2}
                                disabled={true}
                              />
                            </div>
                            <div className="mb-4 md:mr-2 md:mb-0">
                              <label className="block text-gray-700">
                                Exchange Rate
                              </label>
                              <CurrencyInput
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                                id="exchange-rate"
                                name="exchange-rat"
                                defaultValue={parseFloat(
                                  invoiceDetail.exchangeRate
                                )}
                                decimalsLimit={2}
                                disabled={true}
                              />
                            </div>
                          </div>

                          <div className="mb-4 md:flex md:justify-between">
                            <div className="mb-4 md:mr-2 md:mb-0">
                              <label className="block text-gray-700">
                                Date created
                              </label>

                              <input
                                type="text"
                                name="email"
                                value={dayjs(invoiceDetail.date).format(
                                  "DD-MMM-YYYY"
                                )}
                                style={{ transition: "all .15s ease" }}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                              />
                            </div>
                            <div className="mb-4 md:mr-2 md:mb-0">
                              <label className="block text-gray-700">
                                Due Date
                              </label>

                              <input
                                type="text"
                                name="email"
                                value={dayjs(invoiceDetail.dueDate).format(
                                  "DD-MMM-YYYY"
                                )}
                                style={{ transition: "all .15s ease" }}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*footer*/}
                      <div className="flex items-center justify-center p-6 mt-5 rounded-b"></div>
                    </div>
                  </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </>
            ) : null}

            {showSaleDetailModal ? (
              <>
                <div
                  className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                  onClick={() => setShowSaleDetailModal(false)}
                >
                  <div className="fixed w-auto my-6 mx-auto max-w-xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                      {/*header*/}
                      <div className="flex items-start justify-around mt-5 rounded-t">
                        <div className="flex-initial w-64 justify-center">
                          <h3 className="text-red-700 font-medium text-lg mb-1 ">
                            Sale Detail
                          </h3>
                        </div>
                        <button
                          onClick={() => setShowSaleDetailModal(false)}
                          type="button"
                          className=" rounded-md p-1 inline-flex items-center justify-center text-red-400 hover:text-red-500 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500"
                        >
                          <span className="sr-only">Close menu</span>

                          <svg
                            className="h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                      {/*body*/}
                      <div className="relative px-8 flex-auto">
                        <div className="relative w-12/12">
                          <p className="my-4 text-slate-500 text-lg leading-relaxed">
                            Sale detail for
                            <a
                              target="_blank"
                              href={
                                process.env.REACT_APP_ZOHO_BASE_URL +
                                "/" +
                                process.env.REACT_APP_ORGANIZATION_ID +
                                "#/salesorders/" +
                                saleDetail.saleOrderId
                              }
                              class="text-blue-500 underline"
                            >
                              {saleDetail.saleOrderId}
                            </a>
                          </p>

                          <div className="mb-4 md:flex md:justify-between">
                            <div className="w-full">
                              <label className="block text-gray-700">
                                Customer Name
                              </label>

                              <input
                                type="text"
                                name="email"
                                value={saleDetail.customerName}
                                style={{ transition: "all .15s ease" }}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                              />
                            </div>
                          </div>

                          <div className="mb-4 md:flex md:justify-between">
                            <div className="w-full">
                              <label className="block text-gray-700">
                                Reference Number
                              </label>

                              <input
                                type="text"
                                name="email"
                                value={saleDetail.refrenceNumber}
                                style={{ transition: "all .15s ease" }}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                              />
                            </div>
                          </div>

                          <div className="mb-4 md:flex md:justify-between">
                            <div className="mb-4 md:mr-2 md:mb-0">
                              <label className="block text-gray-700">
                                Sales Order Number
                              </label>

                              <input
                                type="text"
                                name="email"
                                value={saleDetail.salesOrderNumber}
                                style={{ transition: "all .15s ease" }}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                              />
                            </div>
                            <div className="mb-4 md:mr-2 md:mb-0">
                              <label className="block text-gray-700">
                                Status
                              </label>

                              <input
                                type="text"
                                name="email"
                                value={saleDetail.status}
                                style={{ transition: "all .15s ease" }}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                              />
                            </div>
                          </div>

                          <div className="mb-4 md:flex md:justify-between">
                            <div className="mb-4 md:mr-2 md:mb-0">
                              <label className="block text-gray-700">
                                Balance
                              </label>

                              <input
                                type="text"
                                name="email"
                                value={saleDetail.balance}
                                style={{ transition: "all .15s ease" }}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                              />
                            </div>
                            <div className="mb-4 md:mr-2 md:mb-0">
                              <label className="block text-gray-700">
                                Currency
                              </label>

                              <input
                                type="text"
                                name="email"
                                value={saleDetail.currencyCode}
                                style={{ transition: "all .15s ease" }}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                              />
                            </div>
                          </div>

                          <div className="mb-4 md:flex md:justify-between">
                            <div className="mb-4 md:mr-2 md:mb-0">
                              <label className="block text-gray-700">
                                Date created
                              </label>

                              <input
                                type="text"
                                name="email"
                                value={dayjs(saleDetail.date).format(
                                  "DD-MMM-YYYY"
                                )}
                                style={{ transition: "all .15s ease" }}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                              />
                            </div>
                            <div className="mb-4 md:mr-2 md:mb-0">
                              <label className="block text-gray-700">
                                Shipment Date
                              </label>

                              <input
                                type="text"
                                name="email"
                                value={dayjs(saleDetail.shipmentDate).format(
                                  "DD-MMM-YYYY"
                                )}
                                style={{ transition: "all .15s ease" }}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*footer*/}
                      <div className="flex items-center justify-center p-6 mt-5 rounded-b"></div>
                    </div>
                  </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </>
            ) : null}

            {showBillDetailModal ? (
              <>
                <div
                  className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                  onClick={() => setShowBillDetailModal(false)}
                >
                  <div className="fixed w-auto my-6 mx-auto max-w-xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                      {/*header*/}
                      <div className="flex items-start justify-around mt-5 rounded-t">
                        <div className="flex-initial w-64 justify-center">
                          <h3 className="text-red-700 font-medium text-lg mb-1 ">
                            Bill Detail
                          </h3>
                        </div>
                        <button
                          onClick={() => setShowBillDetailModal(false)}
                          type="button"
                          className=" rounded-md p-1 inline-flex items-center justify-center text-red-400 hover:text-red-500 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500"
                        >
                          <span className="sr-only">Close menu</span>

                          <svg
                            className="h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                      {/*body*/}
                      <div className="relative px-8 flex-auto">
                        <div className="relative w-12/12">
                          <p className="my-4 text-slate-500 text-lg leading-relaxed">
                            Bill detail for
                            <a
                              target="_blank"
                              href={
                                process.env.REACT_APP_ZOHO_BASE_URL +
                                "/" +
                                process.env.REACT_APP_ORGANIZATION_ID +
                                "#/bills/" +
                                billDetail.billId
                              }
                              class="text-blue-500 underline"
                            >
                              {billDetail.billId}
                            </a>
                          </p>

                          <div className="mb-4 md:flex md:justify-between">
                            <div className="w-full">
                              <label className="block text-gray-700">
                                Vendor Name
                              </label>

                              <input
                                type="text"
                                name="email"
                                value={billDetail.vendorName}
                                style={{ transition: "all .15s ease" }}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                              />
                            </div>
                          </div>

                          <div className="mb-4 md:flex md:justify-between">
                            <div className="w-full">
                              <label className="block text-gray-700">
                                Reference Number
                              </label>

                              <input
                                type="text"
                                name="email"
                                value={billDetail.refrenceNumber}
                                style={{ transition: "all .15s ease" }}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                              />
                            </div>
                          </div>

                          <div className="mb-4 md:flex md:justify-between">
                            <div className="mb-4 md:mr-2 md:mb-0">
                              <label className="block text-gray-700">
                                Bill Number
                              </label>

                              <input
                                type="text"
                                name="email"
                                value={billDetail.invoiceNumber}
                                style={{ transition: "all .15s ease" }}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                              />
                            </div>
                            <div className="mb-4 md:mr-2 md:mb-0">
                              <label className="block text-gray-700">
                                Status
                              </label>

                              <input
                                type="text"
                                name="email"
                                value={billDetail.status}
                                style={{ transition: "all .15s ease" }}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                              />
                            </div>
                          </div>

                          <div className="mb-4 md:flex md:justify-between">
                            <div className="mb-4 md:mr-2 md:mb-0">
                              <label className="block text-gray-700">
                                Balance
                              </label>
                              <CurrencyInput
                                intlConfig={{
                                  locale:
                                    billDetail.currencyCode != "USD"
                                      ? "en-NG"
                                      : "en-US",
                                  currency:
                                    billDetail.currencyCode != "USD"
                                      ? "NGN"
                                      : "USD",
                                }}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                                id="bill-balance"
                                name="bill-balance"
                                defaultValue={billDetail.balance}
                                decimalsLimit={2}
                                disabled={true}
                              />
                            </div>
                            <div className="mb-4 md:mr-2 md:mb-0">
                              <label className="block text-gray-700">
                                Currency
                              </label>

                              <input
                                type="text"
                                name="email"
                                value={billDetail.currencyCode}
                                style={{ transition: "all .15s ease" }}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                              />
                            </div>
                          </div>

                          <div className="mb-4 md:flex md:justify-between">
                            <div className="mb-4 md:mr-2 md:mb-0">
                              <label className="block text-gray-700">
                                Date created
                              </label>

                              <input
                                type="text"
                                name="email"
                                value={dayjs(billDetail.date).format(
                                  "DD-MMM-YYYY"
                                )}
                                style={{ transition: "all .15s ease" }}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                              />
                            </div>
                            <div className="mb-4 md:mr-2 md:mb-0">
                              <label className="block text-gray-700">
                                Due Date
                              </label>

                              <input
                                type="text"
                                name="email"
                                value={dayjs(billDetail.dueDate).format(
                                  "DD-MMM-YYYY"
                                )}
                                style={{ transition: "all .15s ease" }}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*footer*/}
                      <div className="flex items-center justify-center p-6 mt-5 rounded-b"></div>
                    </div>
                  </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </>
            ) : null}

            {/* Purchase Modal */}
            {showPurchaseDetailModal ? (
              <>
                <div
                  className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                  onClick={() => setShowPurchaseDetailModal(false)}
                >
                  <div className="fixed w-auto my-6 mx-auto max-w-xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                      {/*header*/}
                      <div className="flex items-start justify-around mt-5 rounded-t">
                        <div className="flex-initial w-64 justify-center">
                          <h3 className="text-red-700 font-medium text-lg mb-1 ">
                            Purchase Detail
                          </h3>
                        </div>
                        <button
                          onClick={() => setShowPurchaseDetailModal(false)}
                          type="button"
                          className=" rounded-md p-1 inline-flex items-center justify-center text-red-400 hover:text-red-500 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500"
                        >
                          <span className="sr-only">Close menu</span>

                          <svg
                            className="h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                      {/*body*/}
                      <div className="relative px-8 flex-auto">
                        <div className="relative w-12/12">
                          <p className="my-4 text-slate-500 text-lg leading-relaxed">
                            Purchase detail for
                            <a
                              target="_blank"
                              href={
                                process.env.REACT_APP_ZOHO_BASE_URL +
                                "/" +
                                process.env.REACT_APP_ORGANIZATION_ID +
                                "#/purchaseorders/" +
                                purchaseDetail.purchaseOrderId
                              }
                              class="text-blue-500 underline"
                            >
                              {purchaseDetail.purchaseOrderId}
                            </a>
                          </p>

                          <div className="mb-4 md:flex md:justify-between">
                            <div className="w-full">
                              <label className="block text-gray-700">
                                Vendor Name
                              </label>

                              <input
                                type="text"
                                name="email"
                                value={purchaseDetail.vendorName}
                                style={{ transition: "all .15s ease" }}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                              />
                            </div>
                          </div>

                          <div className="mb-4 md:flex md:justify-between">
                            <div className="w-full">
                              <label className="block text-gray-700">
                                Reference Number
                              </label>

                              <input
                                type="text"
                                name="email"
                                value={purchaseDetail.refrenceNumber}
                                style={{ transition: "all .15s ease" }}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                              />
                            </div>
                          </div>

                          <div className="mb-4 md:flex md:justify-between">
                            <div className="mb-4 md:mr-2 md:mb-0">
                              <label className="block text-gray-700">
                                Purchase Order Number
                              </label>

                              <input
                                type="text"
                                name="email"
                                value={purchaseDetail.purchaseOrderNumber}
                                style={{ transition: "all .15s ease" }}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                              />
                            </div>
                            <div className="mb-4 md:mr-2 md:mb-0">
                              <label className="block text-gray-700">
                                Status
                              </label>

                              <input
                                type="text"
                                name="email"
                                value={purchaseDetail.status}
                                style={{ transition: "all .15s ease" }}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                              />
                            </div>
                          </div>

                          <div className="mb-4 md:flex md:justify-between">
                            <div className="mb-4 md:mr-2 md:mb-0">
                              <label className="block text-gray-700">
                                Balance
                              </label>

                              <input
                                type="text"
                                name="email"
                                value={purchaseDetail.balance}
                                style={{ transition: "all .15s ease" }}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                              />
                            </div>
                            <div className="mb-4 md:mr-2 md:mb-0">
                              <label className="block text-gray-700">
                                Currency
                              </label>

                              <input
                                type="text"
                                name="email"
                                value={purchaseDetail.currencyCode}
                                style={{ transition: "all .15s ease" }}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                              />
                            </div>
                          </div>

                          <div className="mb-4 md:flex md:justify-between">
                            <div className="mb-4 md:mr-2 md:mb-0">
                              <label className="block text-gray-700">
                                Date created
                              </label>

                              <input
                                type="text"
                                name="email"
                                value={dayjs(purchaseDetail.date).format(
                                  "DD-MMM-YYYY"
                                )}
                                style={{ transition: "all .15s ease" }}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                              />
                            </div>
                            <div className="mb-4 md:mr-2 md:mb-0">
                              <label className="block text-gray-700">
                                Due Date
                              </label>

                              <input
                                type="text"
                                name="email"
                                value={dayjs(purchaseDetail.dueDate).format(
                                  "DD-MMM-YYYY"
                                )}
                                style={{ transition: "all .15s ease" }}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*footer*/}
                      <div className="flex items-center justify-center p-6 mt-5 rounded-b"></div>
                    </div>
                  </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </>
            ) : null}

            {/* Header */}
            <div className="relative bg-white ">
              <div className="">
                <div>
                  {/* Card stats */}
                  <div className="grid grid-cols-2 gap-4 m-5">
                    <div className=" border rounded-lg shadow-lg w-full px-2 py-6" style={{backgroundColor:'#feecf0'}}>
                    <span className="font-semibold text-md text-blueGray-700">
                                <span className="font-semibold  m-6 relative top-4  px-3 py-1 rounded-lg text-xl border text-white" style={{backgroundColor:'#e1486c'}}>
                                  &#8358;
                                </span>{" "}
                                <CurrencyFormat
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                />
                              </span>
                              <span className="font-semibold text-md text-blueGray-700">
                                <span className="font-semibold text-lg text-black">
                                  &#8358;
                                </span>{" "}
                                <CurrencyFormat
                                  value={parseFloat(openingBalance.naira)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                />
                              </span>
                              <h5 className="text-black relative left-20  text-sm">
                               Opening Balance
                              </h5>
                              <p className="text-sm  sm:relative sm:left-4 text-center text-blueGray-400 mt-2">
                            <span className="font-semibold">
                              <span className="text-sm text-red-700">
                                &#36;
                              </span>{" "}
                              <CurrencyFormat
                                value={parseFloat(openingBalance.dollar)}
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={2}
                              />
                            </span>
                            
                          </p>
                    </div>
                    <div className=" border rounded-lg shadow-lg w-full px-2 py-6" style={{backgroundColor:'#fef5e7'}}>
                    <span className="font-semibold text-md text-blueGray-700">
                                <span className="font-bold  m-6 relative top-4  px-3 py-1 rounded-lg text-xl border text-white" style={{backgroundColor:'#fbdfb1'}}>
                                  &#8358;
                                </span>{" "}
                                <CurrencyFormat
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                />
                              </span>
                              <span className="font-semibold text-md text-blueGray-700">
                                <span className="font-bold text-lg text-black">
                                  &#8358;
                                </span>{" "}
                                <CurrencyFormat
                                  value={parseFloat(closingBalance.naira)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                />
                              </span>
                              <h5 className="text-black relative left-20  text-sm">
                                Closing Balance
                              </h5>
                              <p className="text-sm relative left-4 text-center text-blueGray-400 mt-4">
                            <span className="font-semibold mr-2">
                              <span className="text-sm text-red-700">
                                &#36;
                              </span>{" "}
                              <CurrencyFormat
                                value={parseFloat(closingBalance.dollar)}
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={2}
                              />
                            </span>
                            <span className="whitespace-nowrap">
                              for {forecastNumber} {forecastPeriod}
                            </span>
                          </p>
                    </div>
                  
                    <div className=" border rounded-lg shadow-lg w-full px-2 py-6" style={{backgroundColor:'#e6f3e6'}}>
                    <span className="font-semibold text-md text-blueGray-700">
                                <span className="font-semibold m-6 relative top-4  px-3 py-1 rounded-lg text-xl border text-white" style={{backgroundColor:'#007d05'}}>
                                  &#8358;
                                </span>{" "}
                                <CurrencyFormat
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                />
                              </span>
                              <span className="font-semibold text-md text-blueGray-700">
                                <span className="font-bold text-lg text-black">
                                  &#8358;
                                </span>{" "}
                                <CurrencyFormat
                                  value={parseFloat(totalCashInflow.naira)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                />
                              </span>
                              <h5 className="text-black relative left-20  text-sm">
                                Total Cash inflow
                              </h5>
                              <p className="text-sm relative left-4 text-center text-blueGray-400 mt-2">
                            <span className="font-semibold mr-2">
                              <span className="text-sm text-red-700">
                                &#36;
                              </span>{" "}
                              <CurrencyFormat
                                value={parseFloat(totalCashInflow.dollar)}
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={2}
                              />
                            </span>
                            <span className="whitespace-nowrap">
                              for {forecastNumber} {forecastPeriod}
                            </span>
                          </p>
                    </div>
                    <div className=" border  rounded-lg shadow-lg w-full px-2 py-6" style={{backgroundColor:'#eaeffc'}}>
                    <span className="font-semibold text-md text-blueGray-700">
                                <span className="font-bold  m-6 relative top-4  px-3 py-1 rounded-lg text-xl border text-white" style={{backgroundColor:'#2b58cb'}}>
                                  &#8358;
                                </span>{" "}
                                <CurrencyFormat
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                />
                              </span>
                              <span className="font-semibold text-md text-blueGray-700">
                                <span className="font-bold text-lg text-black">
                                  &#8358;
                                </span>{" "}
                                <CurrencyFormat
                                  value={parseFloat(totalCashOutflow.naira)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                />
                              </span>
                              <h5 className="text-black relative left-20  text-sm">
                                Total Cash Outflow
                              </h5>
                              <p className="text-sm relative left-4 text-center text-blueGray-400 mt-4">
                            <span className="font-semibold mr-2">
                              <span className="text-sm text-red-700">
                                &#36;
                              </span>{" "}
                              <CurrencyFormat
                                value={parseFloat(totalCashOutflow.dollar)}
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={2}
                              />
                            </span>
                            <span className="whitespace-nowrap">
                              for {forecastNumber} {forecastPeriod}
                            </span>
                          </p> 
                    </div>
                  
                    
                  </div>
                </div>
              </div>
            </div>

            <div className="px-4 md:px-10 mx-auto w-full  m-10">
              <div className="flex flex-wrap">
             
                <BarChart/>
              </div>
              <div className=" mt-4">
             <Button onClick={hideTable} className=" hover:bg-amber-950" style={{backgroundColor:'#feecf0',color:'black'}}>Inflow</Button>
            
              
              {showInflowTable && (
                  <>
                 <div className=" justify-center text-center">
                 <p className=" text-lg" ><strong>Inflow Details</strong></p>
                 </div>
                 <div className="space-y-3">
                 <DataGrid
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  checkboxSelection
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                  }}
                  
                  {...data}
                  rows={invoices}
                  setShowSaleDetailModal={setShowInvoiceDetailModal}
                  setSaleDetail={setInvoiceDetail}
                  onRowClick={updateInvoicesDetail}
                  getRowId={(row) => row.id}
                  columns={ [
    {headerName: "#",field: "id", sortable: true, sortbyOrder: "asc" },
    {headerName: "Invoice No.", field: "invoiceNumber", sortable: true },
    { headerName: "Customer Name", field: "customerName", sortable: true },
    { headerName: "Naira Balance", field: "nairaBalance", sortable: false,  renderCell: (params) => <CurrencyCell rows={params.row}  field = "nairaBalance"/>,},
    { headerName: "Dollar Balance", field: "dollarBalance", sortable: false,  renderCell: (params) => <CurrencyCell rows={params.row} field="dollarBalance" />,},
    { headerName: "Balance Due", field: "balance", sortable: true,  
      renderCell: (params) => <CurrencyCell rows={params.row} field= "balance" />,
               },
    {
      headerName: "Due Date",
      field: "dueDate",
      sortable: true,
    },
    {
      headerName: "Currency",
      field: "currencyCode",
      sortable: true,
    },
    {
      headerName: "Status",
      field: "status",
      sortable: true,
    },
  ]}
                  initialState={{
                    pagination: { paginationModel: { pageSize: 5 } },
                  }}
                  pageSizeOptions={[5, 10, 25]}
                />

                     <DataGrid
                     
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  checkboxSelection
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                  }}
                  
                  {...data}
                  rows={sales}
                  onRowClick={updateSaleDetail}
              
                  getRowId={(row) => row.id}
                  columns={ [
    { headerName: "#", field: "id", sortable: true, sortbyOrder: "asc" },
    {headerName: "Sale ID", field: "salesOrderNumber", sortable: true },
    { headerName: "Customer Name", field: "customerName", sortable: true },
    { headerName: "Naira Balance", field: "nairaBalance", sortable: false,  renderCell:(values) => <CurrencyCell rows={values.row} field="nairaBalance" />,},
    { headerName: "Dollar Balance", field: "dollarBalance", sortable: false,  renderCell: (values) => <CurrencyCell rows={values.row} field="dollarBalance" />,},
    { headerName: "Balance Due", field: "balance", sortable: true,  
      renderCell: (values) => <CurrencyCell rows={values.row} field="balance"/>,
               },
    {
        headerName: "Shipment Date",
        field: "shipmentDate",
      sortable: true,
    },
    {
        headerName: "Currency",
        field: "currencyCode",
      sortable: true,
      
    },
    {
        headerName: "Status",
        field: "status",
      sortable: true,
    },
  ]}
  
 
   
                  initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  pageSizeOptions={[5, 10, 25,100]}
                />

                 </div>
                
                  </>
                )}
              
                     
              </div>

              <div className=" mt-4">
              <Button onClick={closeTable} className=" hover:bg-amber-950" style={{backgroundColor:'#feecf0',color:'black'}}>Outflow</Button>
              {showOutflowTable && (
                <>
                <div className=" justify-center text-center">
                 <p className=" text-lg" ><strong>Outflow Details</strong></p>
                 </div>
                 <div className=" space-y-3">
                 <DataGrid
                slots={{
                  toolbar:GridToolbar
                }}
                checkboxSelection
                onRowModesModelChange={(newRowSelectionModel) =>{
                  setRowSelectionModel(newRowSelectionModel)
                }}
                  rows={bills}
                  getRowId={(row) => row.id}
                  onRowClick={updateBillsDetail}
                  columns={ [
    { headerName: "#", field: "id", sortable: true, sortbyOrder: "asc" },
    { headerName: "Bill ID", field: "refrenceNumber", sortable: true },
    { headerName: "Vendor Name", field: "vendorName", sortable: true },
    {headerName: "Naira Balance", field: "nairaBalance", sortable: false, renderCell :(values) => <CurrencyCell rows = {values.row} field="nairaBalance" />},
    { headerName: "Dollar Balance", field: "dollarBalance", sortable: false, renderCell : (values) => <CurrencyCell rows = {values.row} field="dollarBalance"/>},
    { headerName: "Balance Due", field: "balance", sortable: true, renderCell: (values) => <CurrencyCell rows = {values.row} field="balance"/>},
    {
      headerName: "Due Date",
      field: "dueDate",
      sortable: true,
    },
    {
      headerName: "Currency",
      field: "currencyCode",
      sortable: true,
    },
    {
      headerName: "Status",
      field: "status",
      sortable: true,
    },
  ]}
                 
                  initialState={{
                    pagination: { paginationModel: { pageSize: 10 } }
                }}
                pageSizeOptions={[5, 10, 25,100]}
                />
                  
                <DataGrid
                 slots={{
                    toolbar: GridToolbar,
                  }}
                  checkboxSelection
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                  }}
                  
                  {...data}
                  rows={purchases}
                  getRowId={(row) => row.id}
                  onRowClick ={updatePurchaseDetail}
                  columns={ [
    { headerName: "#", field: "id", sortable: true, sortbyOrder: "asc" },
    { headerName: "Purchase ID", field: "purchaseOrderNumber", sortable: true },
    { headerName: "Vendor Name", field: "vendorName", sortable: true },
    {headerName: "Naira Balance", field: "nairaBalance", sortable: false, renderCell :(values) => <CurrencyCell rows = {values.row} field="nairaBalance" /> },
    { headerName: "Dollar Balance", field: "dollarBalance", sortable: false , renderCell :(values) => <CurrencyCell rows = {values.row} field="dollarBalance" />},
    { headerName: "Balance Due",field: "balance", sortable: true, renderCell :(values) => <CurrencyCell rows = {values.row} field="balance" /> },
    {
      headerName: "Delivery Date",
      field: "deliveryDate",
      sortable: true,
    },
    {
      headerName: "Currency",
      field: "currencyCode",
      sortable: true,
    },
    {
      headerName: "Status",
      field: "status",
      sortable: true,
    },
  ]}
                 
                  initialState={{
                    pagination: { paginationModel: { pageSize: 10 } }
                }}
                pageSizeOptions={[5, 10, 25,100]}
                />
                 </div>
               

                </>
              )}
                
                
                   {/* <ExpensesTable
                  caption="Expenses details"
                  data={''}
                  columns={expensesColumns}
                  setShowExpensesDetailModal={setShowExpensesDetailModal}
                  setExpensesDetail={setExpensesDetail}
                />*/}
                 
                   
            
                  
  
      

               
              </div>

              <footer className="block py-4">
                <div className="container mx-auto px-4">
                  <hr className="mb-4 border-b-1 border-blueGray-200" />
                  <div className="flex flex-wrap items-center md:justify-between justify-center">
                    <div className="w-full md:w-4/12 px-4">
                      <div className="text-sm text-blueGray-500 font-semibold py-1">
                        Copyright © {new Date().getFullYear()}{" "}
                        <a
                          href="#"
                          className="text-blueGray-500 hover:text-blueGray-700 text-sm font-semibold py-1"
                        >
                          Manifold Computers
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default withAuth(true)(Dashboard);
