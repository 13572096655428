import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import User from '../pages/User';
import Setting from '../pages/Setting';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import Register from '../pages/Register';
import Rate from '../pages/Rate';
import OpeningBalance from '../pages/OpeningBalance';
import Overdraft from '../pages/Overdraft';
import Inventories from '../pages/Inventories';
import Expenses from '../pages/Expenses';
import Report from '../pages/Report'
import InventoriesTable from '../components/InventoriesTable';
import Item from '../pages/Item';
import Expense from '../pages/Expense';
import Prepayed from '../pages/Prepayed';
export const appRoutes = [
  {
    path: '/login',
    key: '/login',
    component: Login,
  },
  {
    path: '/register/:id',
    key: '/register/:id',
    component: Register,
  },
  {
    path: '/forgot-password',
    key: '/forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/reset-password/:id',
    key: '/reset-password/:id',
    component: ResetPassword,
  },
  {
    path: '/',
    key: '/',
    component: Dashboard,
  },
  {
    path: '/user',
    key: '/user',
    component: User,
  },
  {
    path: '/exchange-rates',
    key: '/exchange-rates',
    component: Rate,
  },
  {
    path: '/opening-balance',
    key: '/opening-balance',
    component: OpeningBalance,
  },
  {
    path: '/inventories',
    key: '/inventories',
    component: Inventories,
  },
  
  {
    path: '/expense',
    key: '/expense',
    component: Expense,
  },
  {
    path: '/setting',
    key: '/setting',
    component: Setting,
  },
  {
    path: '/overdraft',
    key: '/overdraft',
    component: Overdraft,
  },
  {
    path: '/report',
    key: '/report',
    component: Report,
  },
  {
    path: '/item',
    key: '/item',
    component: Item,
  },
  {
    path: '/prepaid',
    key: '/prepaid',
    component: Prepayed,
  },
];
