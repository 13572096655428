import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../components/Sidebar";
import Box from '@mui/material/Box'
import { DataGrid,GridToolbar,GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector, 
  GridExcelExportOptions,
  GridFooterPlaceholder,
  GridPrintExportOptions,
  GridPrintExportMenuItem,

  GridPanelFooter, GridPanelHeader} from '@mui/x-data-grid';
import { useDemoData } from "@mui/x-data-grid-generator";
import { getInventory } from "../redux/slices/inventory";
import { InfinitySpin } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import Modal from "../components/Modal";
import { COLORS } from "@mui/x-data-grid-generator/services/static-data";
import CurrencyFormat from "react-currency-format";
function CustomToolbar () {
  
 return(
  <GridToolbarContainer>
    <GridToolbarFilterButton></GridToolbarFilterButton>
    <GridToolbarColumnsButton></GridToolbarColumnsButton>
    <GridToolbarDensitySelector
    sx={{ color: 'green' }}
     slotProps={{
      tooltip:{title:'change density'},
      
    }} />
     <Box sx={{ flexGrow: 1 }} />
    <GridToolbarExport
     slotProps={{
          tooltip: { title: 'Export data' },
          button: { variant: 'outlined'},
     }}

     
     printOptions={{ disableToolbarButton: false ,  includeCheckboxes: false,  pageStyle: '.MuiDataGrid-root .MuiDataGrid-main { color: rgba(0, 0, 0, 0.87); background-color: gray} ', hideToolbar: true, fileName: 'yourFileName', bodyClassName: '.header',  header: () => (
            <div className="header">
              {/* Your header content goes here */}
              <nav>This is the header</nav>
            </div>
          ),}}
     />
  </GridToolbarContainer>
 )
}

const Inventories = () => {
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const isItemsLoading = useSelector((state) => state.inventory.isItemsLoading);
  const items = useSelector((state) => state.inventory.items.Items);
  const TotalinventoryCost = useSelector((state) => state.inventory.items.totalsumofItems);
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Add leading zero if month or day is less than 10
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    return `${year}-${month}-${day}`;
};
  useEffect(() => {
    dispatch(getInventory())
      .then((result) => {
        console.log(result, "uuuuuuuuuuuuuuuuuuuu");
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, [dispatch]);

  const handleRowClick = (params) => {
    setSelectedRow(params.row);
  };

  const handleCloseModal = () => {
    setSelectedRow(null);
  };

  // Call useDemoData unconditionally
  const { data } = useDemoData({
    rowLength: 10,
    maxColumns: 6,
    visibleFields: ["Item ID", "Item Name", "Available Stock"],
  });

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <nav className="absolute top-0 left-0 w-full">
          <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
            <a
              className=" text-lg  hidden lg:inline-block font-semibold"
              href="#pablo"
              style={{ color: "#671c2d" }}
              onClick={(e) => e.preventDefault()}
            >
              Inventories
            </a>
            <a
              className=" text-lg  hidden lg:inline-block font-semibold"
              href="#pablo"
              style={{ color: "#671c2d" }}
              onClick={(e) => e.preventDefault()}
            >
            <span>Total Inventory Price : </span>
              <CurrencyFormat
                                  value={parseFloat(TotalinventoryCost?TotalinventoryCost:0)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  prefix={"₦"}
                                />
             
            </a>
            <div className=' grid grid-cols-2 space-x-3'>
              <div>
            <input
                                                    type='date'
                                                    name='payment_date'
                                                    min={getCurrentDate()}
                                                    placeholder="Expected Payment Date"
                                                    style={{ transition: "all .15s ease" }}
                                                    className="w-full px-4 py-3 rounded-lg bg-white mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                                                    required

                                                />
                                                </div></div>
            <FontAwesomeIcon
              icon={faBell}
              className="border px-3 py-3 rounded-full"
              style={{ fontSize: 13 }}
            />
          
          </div>
        </nav>
        <div className="py-11">
          <div className="border-t border-gray-200"></div>
        </div>

        {isLoading ? (
          <div className="flex justify-center h-screen items-center">
            <InfinitySpin width="200" color="red" />
          </div>
        ) : (
          <div className="m-6" style={{ height: 400, width: "95%" }}>
            {!isItemsLoading && (
              <>
                <DataGrid
                  slots={{
                    toolbar: CustomToolbar,
                  }}
                  sx={{
                    url:('../assets/logo.png')
                  }}
                  checkboxSelection
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                  }}
                  onRowClick={handleRowClick}
                  rowSelectionModel={rowSelectionModel}
                  {...data}
                  rows={items}
                  getRowId={(row) => row.item_id}
                  columns={[
                    { field: "item_id", headerName: "Item ID", sortable: true, width: 150 },
                    { field: "name", headerName: "Item Name", sortable: true, width: 200 },
                    { field: "available_stock", headerName: "Available Stock", sortable: true, width: 200 },
                    { field: "account_name", headerName: "Account Name", sortable: true, width: 200 },
                    { field: "description", headerName: "Description", sortable: true, width: 200 },
                    { field: "manufacturer", headerName: "Manufacturer", sortable: true, width: 200 },
                    { field: "rate", headerName: "Item Price", sortable: true, width: 200 },
                    { field: "totalRate", headerName: "Total Item Price", sortable: true, width: 200 },
                  ]}
                  initialState={{
                    pagination: { paginationModel: { pageSize: 5 } },
                  }}
                  pageSizeOptions={[5, 10, 25]}
                />
                {selectedRow && (
                  <Modal isOpen={selectedRow !== null} onClose={handleCloseModal}>
                  <ul className=" list-none">
                 <li className=" border-b border-gray-200 py-2 text-lg"><strong>Item ID:</strong>{selectedRow.item_id}</li>
                 <li className=" border-b border-gray-200 py-2 text-lg"><strong>Item Name:</strong> {selectedRow.name}</li>
                  <li className=" border-b border-gray-200 py-2 text-lg"><strong>Available Stock:</strong> {selectedRow.available_stock}</li>
                  <li className=" border-b border-gray-200 py-2 text-lg"><strong>Account Name:</strong> {selectedRow.account_name}</li>
                  <li className=" border-b border-gray-200 py-2 text-lg"><strong>Description:</strong> {selectedRow.description}</li>
                  <li className=" border-b border-gray-200 py-2 text-lg"><strong>Manufacturer:</strong> {selectedRow.manufacturer}</li>
                  <li className=" border-b border-gray-200 py-2 text-lg"><strong>Item Price:</strong> {selectedRow.rate}</li>
                  <li className=" border-b border-gray-200 py-2 text-lg"><strong>Total Item Price:</strong> {selectedRow.totalRate}</li>
                  </ul>
                  </Modal>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Inventories;
