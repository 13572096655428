import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import logo from "../assets/logo.png";
import { NavLink } from "react-router-dom";
import { forgotPassword } from '../redux/slices/auth'
import pixel from "../assets/second-image.jpeg"
const ForgotPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isForgotPasswordLoading = useSelector(state => state.auth.isForgotPasswordLoading)
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        dispatch(forgotPassword({ email: data.email })).then((res) => {
            if (!res.payload) return

            // TODO:: should redirect to login page
            navigate('/login');
        })
    }


    return (
      <div className="w-screen">
         <div className=" grid grid-cols-2">
        <div className=' h-screen m-auto ' style={{backgroundColor:"#f8f9f9"}}>
            <div className='relative top-12 m-16'>
            <div className='space-y-2'>
            <h1 className='font-bold text-3xl '>Forgot Password</h1>
            <p className=' text-lg font-normal' style={{color:'#7c7e8c'}}>Enter the email you used to sign up and we'll send you a temporary password.</p>
            </div>
            <div className=' relative top-10 space-y-4'>
               <div  className=''>
               <input
                  type="email"
                  id="email"
                  placeholder="Enter email address"
                  className="w-full px-4 py-3 rounded-lg bg-white mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                  required
                />
               </div>
                <button
                type="submit"
                
                className="w-full block bg-red-400 hover:bg-red-300 focus:bg-red-300 text-white font-semibold rounded-lg
                px-4 py-3 mt-6" 
                style={{backgroundColor:'#f5426c'}}
              >
                Reset Password
              </button>
              <div className=' text-center justify-center  bg-white px-2 py-2'>
                <p  className='' style={{color:'#7c7e8c'}}>Didn't get a link ? <text className=' font-semibold' style={{color:'#f5426c'}}>Resend in 00:30</text></p>
            </div>
            </div>
            </div>
         </div>
        <div className=" h-screen">
            <img src={pixel} className=" bg-cover h-screen w-full " />
        </div>
        
       </div>
      </div>
    )
}

export default ForgotPassword;