import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {Axios} from "../../api/instances"; 
import { toast } from "react-toastify";
//import axios from "axios";

const initialState = {
  isExpensesLoading: false,
  error: null,
  expenses: [],
};




const getNonbillableExpense = createAsyncThunk(
  'expense',
  async () => {
    try {
     
      let accessToken = localStorage.getItem("accessToken")
        ? JSON.parse(localStorage.getItem("accessToken"))
        : null;
      let zohoAccessToken = localStorage.getItem("zohoAccessToken")
        ? JSON.parse(localStorage.getItem("zohoAccessToken"))
        : null;
        console.log(accessToken,888888888)
      const response = await Axios.get(
        "/zoho/bank/getallaccrued",
        { "accessToken": zohoAccessToken },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    
      
      
      return response.data.data;
    } catch (error) {
      throw error.response.data || error.message;
    }
  }
);
const getPrepaidExpense = createAsyncThunk(
  'getPrepaidExpense',
  async () => {
    try {
      let accessToken = localStorage.getItem("accessToken")
        ? JSON.parse(localStorage.getItem("accessToken"))
        : null;
      let zohoAccessToken = localStorage.getItem("zohoAccessToken")
        ? JSON.parse(localStorage.getItem("zohoAccessToken"))
        : null;
      const response = await Axios.get(
        "/zoho/bank/getallprepaidexp",
        { "accessToken": zohoAccessToken },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
     
      
      
      return response.data.data;
    } catch (error) {
      throw error.response.data || error.message;
    }
  }
);
const saveAccuredExpense= createAsyncThunk(
  'accuredSave',
  async (id, paymentConfirmation, account_id, tax_id, payment_date, amount, reference_number, customer_id, description, currency_id, currency) => {
    try {
      let accessToken = localStorage.getItem("accessToken")
        ? JSON.parse(localStorage.getItem("accessToken"))
        : null;
      let zohoAccessToken = localStorage.getItem("zohoAccessToken")
        ? JSON.parse(localStorage.getItem("zohoAccessToken"))
        : null;
      const response = await Axios.put(
        "/zoho/updateAccrued",
        {id, paymentConfirmation, account_id, tax_id, payment_date, amount, reference_number, customer_id, description, currency_id, currency},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

    
      
     
      
      console.log(response,667777777777)
      return response.data.data;
    } catch (error) {
      throw error.response.data || error.message;
    }
  }
);

const savePrepaidExpense= createAsyncThunk(
  'prepaidSave',
  async ({id, paymentConfirmation, account_id, tax_id, payment_date, amount, reference_number, customer_id, description, currency_id, currency}) => {
    try {
      let accessToken = localStorage.getItem("accessToken")
        ? JSON.parse(localStorage.getItem("accessToken"))
        : null;
      let zohoAccessToken = localStorage.getItem("zohoAccessToken")
        ? JSON.parse(localStorage.getItem("zohoAccessToken"))
        : null;
      const response = await Axios.put(
        "/zoho/updatePrepaid",
        {id, paymentConfirmation, account_id, tax_id, payment_date, amount, reference_number, customer_id, description, currency_id, currency},
     
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      
     
      
      console.log(response,444444555555555)
      return response.data.data;
    } catch (error) {
      throw error.response.data || error.message;
    }
  }
);
const deleteAccuredExpense= createAsyncThunk(
  'accuredDelete',
  async () => {
    try {
      let accessToken = localStorage.getItem("accessToken")
        ? JSON.parse(localStorage.getItem("accessToken"))
        : null;
      let zohoAccessToken = localStorage.getItem("zohoAccessToken")
        ? JSON.parse(localStorage.getItem("zohoAccessToken"))
        : null;
      const response = await Axios.post(
        "/zoho/accured/delete/:id",
       
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      
     
      
      console.log(response,55555555)
      return response.data.data;
    } catch (error) {
      throw error.response.data || error.message;
    }
  }
);


const deletePrepaidExpense= createAsyncThunk(
  'prepaidDelete',
  async () => {
    try {
      let accessToken = localStorage.getItem("accessToken")
        ? JSON.parse(localStorage.getItem("accessToken"))
        : null;
      let zohoAccessToken = localStorage.getItem("zohoAccessToken")
        ? JSON.parse(localStorage.getItem("zohoAccessToken"))
        : null;
      const response = await Axios.post(
        "/zoho/prepaid/delete/:id",
       
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      
     
      
      console.log(response,55555555)
      return response.data.data;
    } catch (error) {
      throw error.response.data || error.message;
    }
  }
);

const postNonbillableExpense = createAsyncThunk(
  'expense',
  async () => {
    try {
      let accessToken = localStorage.getItem("accessToken")
        ? JSON.parse(localStorage.getItem("accessToken"))
        : null;
      let zohoAccessToken = localStorage.getItem("zohoAccessToken")
        ? JSON.parse(localStorage.getItem("zohoAccessToken"))
        : null;
      const response = await Axios.post(
        "/zoho/bank/getallaccrued",
        { "accessToken": zohoAccessToken },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
     
      
      
      return response.data.data;
    } catch (error) {
      throw error.response.data || error.message;
    }
  }
);
const nonbillSlice = createSlice({
  name: "nonbill",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getNonbillableExpense.pending, (state, action) => {
        state.isExpensesLoading = true;
      })
      .addCase(getNonbillableExpense.fulfilled, (state, action) => {
        state.isExpensesLoading = false;
        state.expenses = action.payload; 
        console.log("fulfilled", action.payload);
        
      })
      .addCase(getNonbillableExpense.rejected, (state, action) => {
        toast.warning("An error occurred", {
          autoClose: 2000,
        });
        state.isExpensesLoading = false;
      })
      .addCase(getPrepaidExpense.pending, (state, action) => {
        state.isExpensesLoading = true;
      })
      .addCase(getPrepaidExpense.fulfilled, (state, action) => {
        state.isExpensesLoading = false;
        state.expenses = action.payload;
      })
      .addCase(getPrepaidExpense.rejected, (state, action) => {
        toast.warning("An error occurred while fetching prepaid expenses", {
          autoClose: 2000,
        });
        state.isExpensesLoading = false;
        state.error = action.error.message || "Failed to fetch prepaid expenses";
      })
      .addCase(deleteAccuredExpense.pending, (state, action) => {
        state.isExpensesLoading = true;
      })
      .addCase(deleteAccuredExpense.fulfilled, (state, action) => {
        state.isExpensesLoading= false;
       state.rowData=  action.payload;
        console.log("fullfilled", action.payload);
        
      })
      .addCase(deleteAccuredExpense.rejected, (state, action) => {
        toast.warning("An error occurred", {
          autoClose: 2000,
        })
        
        state.isExpensesLoading = false;
      })

      .addCase(deletePrepaidExpense.pending, (state, action) => {
        state.isExpensesLoading = true;
      })
      .addCase(deletePrepaidExpense.fulfilled, (state, action) => {
        state.isExpensesLoading= false;
       state.expenses=  action.payload;
        console.log("fullfilled", action.payload);
        
      })
      .addCase(deletePrepaidExpense.rejected, (state, action) => {
        toast.warning("An error occurred", {
          autoClose: 2000,
        })
        
        state.isExpensesLoading = false;
      })
     
      .addCase(saveAccuredExpense.pending, (state, action) => {
        state.isExpensesLoading = true;
      })
      .addCase(saveAccuredExpense.fulfilled, (state, action) => {
        state.isExpensesLoading= false;
       state.expenses =  action.payload;
        console.log("fullfilled", action.payload);
        
      })
      .addCase(saveAccuredExpense.rejected, (state, action) => {
        toast.warning("An error occurred", {
          autoClose: 2000,
        })
        
        state.isExpensesLoading = false;
      })
      .addCase(savePrepaidExpense.pending, (state, action) => {
        state.expenses = true;
      })
      .addCase(savePrepaidExpense.fulfilled, (state, action) => {
        state.isExpensesLoading= false;
       state.expenses =  action.payload;
        console.log("fullfilled", action.payload);
        
      })
      .addCase(savePrepaidExpense.rejected, (state, action) => {
        toast.warning("An error occurred", {
          autoClose: 2000,
        })
        
        state.isExpensesLoading = false;
      });
  },
});

export { getNonbillableExpense,getPrepaidExpense,deleteAccuredExpense,deletePrepaidExpense,saveAccuredExpense,savePrepaidExpense };

export default nonbillSlice.reducer;






































































































































































































